export const ACTIVE_NETWORK = 137;

export const CONJURE_FACTORY_ADDRESS = "0x10EcBAA95Bcc1b73DD790fc9d68E166CD72B2F1C";
export const ETHUSD_CHAINLINK_ADDRESS = "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0";

export const CHAINLINK_OPTIONS = [
    {
        label: "AGI/USD",
        value: "0x29e5BfDe98498CaA5a8ddD73E94E47104C3A6c71"
    },
    {
        label: "BTC/USD",
        value: "0xc907E116054Ad103354f2D350FD2514433D57F6f"
    },
    {
        label: "DAI/USD",
        value: "0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D"
    },
    {
        label: "DOT/USD",
        value: "0xacb51F1a83922632ca02B25a8164c10748001BdE"
    },
    {
        label: "ETH/USD",
        value: "0xF9680D99D6C9589e2a93a78A04A279e509205945"
    },
    {
        label: "LINK/USD",
        value: "0xd9FFdb71EbE7496cC440152d43986Aae0AB76665"
    },
    {
        label: "MATIC/USD",
        value: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0"
    },
    {
        label: "SNX/USD",
        value: "0xbF90A5D9B6EE9019028dbFc2a9E50056d5252894"
    },
    {
        label: "SUSHI/USD",
        value: "0x49B0c695039243BBfEb8EcD054EB70061fd54aa0"
    },
    {
        label: "USDC/USD",
        value: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7"
    },
    {
        label: "USDT/USD",
        value: "0x0A6513e40db6EB1b165753AD52E80663aeA50545"
    },
        {
            label: "WBTC/USD",
            value: "0xDE31F8bFBD8c84b5360CFACCa3539B938dd78ae6"
        }
]
;