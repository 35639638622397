import React, {useState} from "react";
import "./create.scss";
import {useEffect} from 'react';
import {useWeb3React} from "@web3-react/core";
import {addToast} from "../../hooks/useToast";
import { useHistory } from "react-router";
import {gql, useQuery} from "@apollo/client";
import Select from "react-select";

const EXCHANGE_RATES = gql`
    query GetExchangeRates {
        openOracleFrameworkInstances {
            id
            signerThreshold
            payoutAddress
            feesAccrued
            passPrice
        }
    }
`;

const Create = () => {
    const [oracleList, setOracleList] = useState([])
    const { loading, error, data } = useQuery(EXCHANGE_RATES, {
        pollInterval: 500,
    });

    const [tableData, setTableData] = useState(
        [
        {
        ticker: "",
        name: "Table is Loading" ,
        price: "",
        supply: "",
    }
    ])


    async function getConjureAssets() {
        if (error) return;

        console.log(data)

        let i;
        let temp_list = []
        console.log()

        for (i=0; i < data['openOracleFrameworkInstances'].length;i++) {
            console.log(data['openOracleFrameworkInstances'][i]);

            let jsoner = {label: data['openOracleFrameworkInstances'][i]['id'], value: data['openOracleFrameworkInstances'][i]['id']}
            temp_list.push(jsoner)
        }

        setOracleList(temp_list);
    }

    // effect hook for updating data
    useEffect(() => {
        if (loading === false) {
            getConjureAssets();
        }
    }, [loading]);

    return (
        <div>
                <Select
                    options={oracleList}

                />
        </div>
    );
};
export default Create;
