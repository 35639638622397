import React, {useState} from "react";
import "./home.scss";
import {useEffect} from 'react';
import {addToast} from "../../hooks/useToast";
import {gql, useQuery} from "@apollo/client";
import Select from "react-select";

const EXCHANGE_RATES = gql`
    query GetExchangeRates {
        openOracleFrameworkInstances {
            id
            signerThreshold
            payoutAddress
            feesAccrued
            passPrice
            signerList {
                id
                active
                signer
            }
            instanceOracleFeeds{
                name
                id
                description
                decimal
                price
                mode
                instanceOracleFeedSignerValue( orderBy: roundId, orderDirection: desc) {
                    id
                    roundId
                    value
                    signer
                }

            }
        }
    }
`;

const Home = () => {
    const [oracleList, setOracleList] = useState([]);
    const [selected, setSelected] = useState("");
    const { loading, error, data } = useQuery(EXCHANGE_RATES, {
        pollInterval: 500,
    });

    const [tableData, setTableData] = useState(
        [
            {
                ticker: "",
                name: "Table is Loading" ,
                price: "",
                supply: "",
            }
        ])


    function logArrayElements(element, index, array) {
        console.log('a[' + index + '] = ' + element);
    }

    async function getConjureAssets() {
        if (error) return;

        console.log('getting data')
        console.log(data)

        let i;
        let temp_list = []
        let health = {}
        let feedHealth = {}

        for (i=0; i < data['openOracleFrameworkInstances'].length;i++) {
            console.log('list instance')
            console.log(data['openOracleFrameworkInstances'][i]);

            let j;
            health = {}
            let feedLen = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'].length

            for(j=0; j < feedLen; j++) {
                let sigvalues = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['instanceOracleFeedSignerValue']
                let feedid = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['id']

                health[feedid] = {}

                // calc values
                let k;
                for(k=0; k < sigvalues.length; k++) {

                    if (health[feedid][sigvalues[k]['roundId']] === NaN || health[feedid][sigvalues[k]['roundId']] === undefined) {
                        health[feedid][sigvalues[k]['roundId']] = [sigvalues[k]['signer']]
                    }
                    else if (health[feedid][sigvalues[k]['roundId']].includes(sigvalues[k]['signer'])) {

                    }
                    else {
                        health[feedid][sigvalues[k]['roundId']].push(sigvalues[k]['signer'])
                    }
                }

                let keyer = []
                for (const [key, value] of Object.entries(health[feedid])) {
                    keyer.push(key)
                }

                console.log('list keyer')
                console.log(keyer)

                let sum = 0
                keyer.forEach(function(item){
                    sum += health[feedid][item].length
                });

                console.log('list health total')
                console.log(health)

                console.log('stats')
                console.log(sum)
                console.log( parseInt(data['openOracleFrameworkInstances'][i]['signerThreshold']))
                console.log(keyer.length)

                let health1 = sum / parseInt(data['openOracleFrameworkInstances'][i]['signerList'].length) / keyer.length
                console.log('list health')
                console.log(health1)
                feedHealth[feedid] = health1
            }

            console.log(health)


            let feeddata = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds']


            let jsoner = {label: data['openOracleFrameworkInstances'][i]['id'],
                value: data['openOracleFrameworkInstances'][i]['id'],
                signerThreshold: data['openOracleFrameworkInstances'][i]['signerThreshold'],
                payoutAddress: data['openOracleFrameworkInstances'][i]['payoutAddress'],
                signerList: data['openOracleFrameworkInstances'][i]['signerList'],
                instanceOracleFeeds: feeddata,
                feedHealth: feedHealth}
            temp_list.push(jsoner)
        }

        setOracleList(temp_list);
    }

    function handleSelectChange(event) {
        console.log(event)
        setSelected(event)
    }

    // effect hook for updating data
    useEffect(() => {
        if (loading === false) {
            getConjureAssets();
        }
    }, [loading]);

    return (
        <div>
            <div className="item item2" style={{ width: "100%", height: "150px" }}>
                <div style={{width: '100%', color:"black"}}>
                    <Select
                        options={oracleList}
                        style={{ width: "100%" }}
                        onChange={e => handleSelectChange(e)}
                    />
                </div>
            </div>

            {selected === "" ?
            ""
            :
                <div className="do-center">
                    <div className="mint">
                        <div>
                            <div className="details do-between">
                                <span>Signer Length</span>
                                <span>{selected.signerList.length}</span>
                            </div>
                            <div className="details do-between">
                                <span>Signers</span>
                            </div>
                            {selected.signerList.map((prop, i) => (
                                <div className="details do-between">
                                    <span>{prop.signer}</span>
                                </div>

                            ))}
                            <div className="details do-between">
                                <span>Signer Threshold</span>
                                <span>{selected.signerThreshold}</span>
                            </div>
                            <div className="details do-between">
                                <span>Payout Address</span>
                            </div>
                            <div className="details do-between">
                                <span>{selected.payoutAddress}</span>
                            </div>
                            <div className="details do-between">
                                <span>#Feeds</span>
                                <span>{selected.instanceOracleFeeds.length}</span>
                            </div>
                            <div className="details do-between">
                                <span>Feed Details</span>
                            </div>
                            {selected.instanceOracleFeeds.map((prop, i) => (
                                <div className="details do-between">
                                    <span>{prop.name}</span>
                                    <span>{prop.description}</span>
                                    <span>{selected.feedHealth[prop.id]}</span>
                                </div>

                            ))}
                        </div>

                    </div>
                </div>
            }


        </div>
    );
};

export default Home;
