import React, { Component, useEffect, useState } from "react";
import { StakeDetails, StakeDetailsParamsMapping } from "../../data";
import "./stake.scss";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import backIco from "../../assets/others/BackButton.svg";
import { StakeData } from "../../data";

import { parseEther, formatEther } from "@ethersproject/units";
import usePoolContract from "../../hooks/usePoolContract";
import useErc20Contract from "../../hooks/useErc20Contract";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";
import getReceipt from "../../lib/getReceipt";
import useToast from "../../hooks/useToast";

const TableDetails = ({ data, params }) => {
  console.log(params);
  return (
    <div className="table-details">
      <table>
        <tbody>
          {params.map((i, k) => (
            <tr>
              <td style={{ textAlign: "left" }}>
                {}
                {i == "stakedUniv2"
                  ? `${StakeDetailsParamsMapping[i]} : ${data.univ2}`
                  : StakeDetailsParamsMapping[i]}
              </td>
              <td>:</td>
              <td style={{ textAlign: "right" }}>{data[i]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Details = ({ match }) => {
  const [{ id, status }, setUser] = useState(match.params);
  const [details, setDetails] = useState(StakeDetails);
  const [amount, setAmount] = useState("");
  const [stakeData, setStakeData] = useState(StakeData);
  let history = useHistory();
    const addToast = useToast();

  let staker = stakeData[id-1]

  console.log(id)
    console.log(staker)

    const contract = usePoolContract(staker.poolAddress);

    const { account, library } = useWeb3React();
    const maxAllowance = BigNumber.from(2).pow(256).sub(1);
    const erc20 = useErc20Contract(staker.tokenAddress);

    //set constants for later displaying
    const [first_loop, set_first_loop] = useState(0);
    const [ercBalance, set_ercBalance] = useState(0);
    const [rewardEarned, set_rewardEarned] = useState(0);
    const [amountStaked, set_amountStaked] = useState(0);
    const [hasSetAllowance, set_hasSetAllowance] = useState(false);

    // effect hook for updating data
    useEffect(() => {

        // update the ui elements
        async function updateUIStates() {

            {
                const allowance = await erc20.allowance(account, staker.poolAddress);
                const hasSetAllowance = allowance._hex === "0x00" ? false : true;
                set_hasSetAllowance(hasSetAllowance);

                const balance = await erc20.balanceOf(account);
                set_ercBalance(balance);
                const earned = await contract.earned(account);
                set_rewardEarned(earned);
                const staked = await contract.balanceOf(account);
                set_amountStaked(staked);
            }
        }

        // fix for updating after wallet login
        if (account && first_loop == 0)
        {
            set_first_loop(1);
            updateUIStates();
        }

        // schedule every 15 sec refresh
        const timer = setInterval(() => {
            if (account)
            {
                updateUIStates()
            }

        }, 3000);

        // clearing interval
        return () => clearInterval(timer);
    }, [ account, erc20, contract, staker.poolAddress] );

    const callExit = async () => {
        try {
            const { hash } = await contract.exit();
            await getReceipt(hash, library);
        } catch (e) {
            addToast({ body: e.message, type: "error" });
        }
    };

    const callRewards = async () => {
        try {
            const { hash } = await contract.getReward();
            await getReceipt(hash, library);
        } catch (e) {
            addToast({ body: e.message, type: "error" });
        }
    };

    const callApprove = async () => {
        try {
            const { hash } = await erc20.approve(staker.poolAddress, maxAllowance);
            await getReceipt(hash, library);
        } catch (e) {
            addToast({ body: e.message, type: "error" });
        }
    };

    const callStake = async (values) => {
        try {
            if (!amount) {
                throw new Error("Value must be greater than 0");
            }

            const stakeInWei = parseEther(amount);

            console.log(stakeInWei)

            const { hash } = await contract.stake(stakeInWei);

            await getReceipt(hash, library);
        } catch (e) {
            addToast({ body: e.message, type: "error" });
        }
    };


    function format_friendly(input, decimals)
    {
        const temp = formatEther(input);
        const words = temp.split('.');

        const slicer = words[1].slice(0,decimals);
        const returner = words[0] + "." + slicer;

        return returner;
    }

  return (
    <div className="stake-details">
      <span onClick={() => history.goBack()} className="back-button">
        <img src={backIco} />
      </span>
      <div>
        <div>
          <div className="heading">earned rewards</div>
            <div className="table-details">
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "left" }}>
                                Amount Staked:
                            </td>
                            <td>:</td>
                            <td style={{ textAlign: "right" }}>{formatEther(amountStaked)}</td>
                        </tr>
                        {staker.id !== 1 ?
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    Rewards per Day:
                                </td>
                                <td>:</td>
                                <td style={{ textAlign: "right" }}>1650 CNJ</td>
                            </tr>
                        :
                        ""}

                    </tbody>
                </table>
            </div>
        </div>
        <div className="do-center">
            {staker.id !== 1 ?
                <div className="heading">{formatEther(rewardEarned)} CNJ Pending</div>
                :
                <div className="heading">{formatEther(rewardEarned)} ETH Pending</div>
            }

          <div className="connect-button" onClick={callRewards}>claim</div>
          <div className="connect-button" onClick={callExit}>exit</div>
        </div>
      </div>
      <div>
        <div>
          <div className="heading">
            {status == "finished" ? "CC10" : `Staking Token : ${staker.heading}`}
          </div>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* {status == "stake" ? (
            <div className="heading">{details.earnedRewards}</div>
          ) : null} */}
            { hasSetAllowance ?
                <div>
                <div className="item">
                    {status == "stake" && <div>Bal {formatEther(ercBalance)}</div>}
                    <input
                        placeholder="Amount"
                        onChange={(e) => setAmount(e.target.value)}
                    />

                </div>
                <div className="connect-button" onClick={callStake}>Stake</div>
                </div>
            :
                <div className="connect-button" onClick={callApprove}>Approve</div>
            }


        </div>
      </div>
    </div>
  );
};

export default Details;
