import Ico from "./assets/others/Trending.svg";
import CurrencyIco from "./assets/others/LogoCircle@2x.png";
import BitcoinIco from "./assets/others/bitcoin-btc-logo.svg";
import EthereumIco from "./assets/others/ethIcon.svg";
import UniswapIco from "./assets/others/UniIcon.svg";
import ConjureIco from "./assets/others/ConjureIcon@2x.png";
import LitecoinIco from "./assets/others/litecoin-ltc-logo.svg";

export const MintData = {
  currentPrice: "$64,342.00",
  fixedRatio: "200%",
  mintFee: "1%",
};

export const TrendingAssetsData = [
  {
    ticker: "BTC",
    name: { icon: BitcoinIco, name: "Bitcoin" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "ETH",
    name: { icon: EthereumIco, name: "Ethereum" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "UNI",
    name: { icon: UniswapIco, name: "Uniswap" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "CNJ",
    name: { icon: ConjureIco, name: "Conjure" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "LTC",
    name: { icon: LitecoinIco, name: "Litecoin" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
];

export const RecentlyCreatedAssetsData = [
  {
    ticker: "BTC",
    name: { icon: BitcoinIco, name: "Bitcoin" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "ETH",
    name: { icon: EthereumIco, name: "Ethereum" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "UNI",
    name: { icon: UniswapIco, name: "Uniswap" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "CNJ",
    name: { icon: ConjureIco, name: "Conjure" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "LTC",
    name: { icon: LitecoinIco, name: "Litecoin" },
    price: "231$",
    supply: "231$",
  },
];

export const LoanTableData = [
  {
    name: { icon: BitcoinIco, name: "Bitcoin" },

    symbol: "BTC",
    amount_borrowed: "11.0",
    collateral_ratio: "6%",
  },
  {
    name: { icon: EthereumIco, name: "Ethereum" },

    symbol: "ETH",
    amount_borrowed: "12.0",
    collateral_ratio: "8%",
  },
  {
    name: { icon: UniswapIco, name: "Uniswap" },

    symbol: "UNI",
    amount_borrowed: "55.0",
    collateral_ratio: "1%",
  },
  {
    name: { icon: ConjureIco, name: "Conjure" },

    symbol: "CNJ",
    amount_borrowed: "23.0",
    collateral_ratio: "5%",
  },
  {
    name: { icon: LitecoinIco, name: "Litecoin" },

    symbol: "LTC",
    amount_borrowed: "43.0",
    collateral_ratio: "7%",
  },
];

export const ManageTableData = [
  {
    event: "Burn",
    address: "0xgye32rsas3353fsss",
    amount: "10.0",
    time: "231$",
  },
  {
    event: "Mint",
    address: "B0xertewerrcd33r32asTC",
    amount: "13.0",
    time: "231$",
  },
  {
    event: "Burn",
    address: "0xe23hfg5sdh3jhjdf4",
    amount: "12.0",
    time: "231$",
  },
];

export const StakeData = [
  {
    icon: CurrencyIco,
    heading: "Conjure (CNJ)",
    rate: "1650 CNJ/DAY",
    total: "465,422 CNJ",
    status: "stake",
    staked: "145,346",
    univ2: "ETH - DEGENz",
    apy: "5%",
    id: 1,
    poolAddress: "0x6B13053bb82f83a87c6cc139841254170271cb4C",
    tokenAddress: "0x2b76F9E7BecC7B4381EEB1894385045B1aA7fBAb",
  },
];

export const AssetData = {
  assetType :{
  label: "Single Asset",
      id: 0,
   },
  inverseAsset: false,
  mintingFee: "0.0",
  cRatio: "120",
  divisor: "",
  name: "",
  symbol: ""
};

export const CreateButtons = [
  { name: "LINK", id: "link" },
  { name: "CUSTOM", id: "custom" },
  { name: "CONJURE", id: "conjure" },
];

export const StakeDetails = {
  staked: "9654.22 CC10",
  univ2: "ETH - ORCL5",
  rate: "465,422 NDX/DAY",
  estReward: "0 NDX/DAY",
  poolWeight: "0 %",
  rewardsPool: "0Xe1de...Ba35",
  stakingEnds: "Mar 8, 2021, 2:32 PM UTC",
  stakedUniv2: "41,316.04",
  stakedCC10: "41,316.04",
  ndxPerDay: "9,374.99",
  totalNdx: "562,500",
  stakingToken: "CC10",
  earnedRewards: "0 NDX",
  balance: "1000000",
};

export const StakeDetailsParamsMapping = {
  staked: "Staked",
  univ2: "UNIV2",
  rate: "Rate",
  estReward: "Est Reward",
  poolWeight: "Pool Weight",
  rewardsPool: "Rewards Pool",
  stakingEnds: "Staking Ends",
  stakedUniv2: "STAKED Univ2",
  stakedCC10: "Staked Cc10",
  ndxPerDay: "NDX Per Day",
  totalNdx: "Total NDX",
  stakingToken: "staking Token",
  earnedRewards: "earned Rewards",
  balance: "balance",
};

export const AssetDetails = {
  assetBalance: "XEM",
  latestPrice: "200",
  recordedOn: "1.5%",
  assetsInCirculation: "2%",
  priceAtCreation: "2%",
};
