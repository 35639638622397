import React, { Component, useEffect, useState } from "react";
import Modal from "../subcomponents/modal";
import Table from "../subcomponents/table";
import LoanModal from "./loanModal";
import OpenLoanIco from "../../assets/others/OpenLoanIcon.svg";

import {formatEther, parseEther, formatUnits} from "@ethersproject/units";
import {useWeb3React} from "@web3-react/core";
import {addToast} from "../../hooks/useToast";
import {BigNumber} from '@ethersproject/bignumber';
import {DateTime} from "luxon";
import {format_friendly, getEtherscanLink} from "../../lib/utils";
import { useQuery, gql } from '@apollo/client';
import COLL_ABI from "../../constants/abi/EtherCollateral.json";
import {isAddress} from "@ethersproject/address";
import {Contract} from "@ethersproject/contracts";

const Loan = () => {
  const [modal, setModal] = useState(false);
  const [clickedRow, setClickedRow] = useState({});

    const {account, library} = useWeb3React();

    const EXCHANGE_RATES = gql`
        query GetExchangeRates {
            loans(first: 1000) {
                id
                loanID
                symbol
                borrower
                collateralAddress
                assetAddress
                loanAmount
                collateralAmount
                cRatio
                loanOpen
            }
        }
    `;

    const { loading, error, data  } = useQuery(EXCHANGE_RATES, {
        pollInterval: 500,
    });

    const [LoanTableData, setLoanTableData] = useState([
        {
            name: "" ,

            symbol: "Table is loading",
            amount_borrowed: "",
            collateral_ratio: "",
        },
    ])

  const onRowClick = async (i, k) => {
    setClickedRow(i);
    setModal(!modal);
  };

    async function getUserLoans()
    {
        if (!account)
        {
            addToast({body:"Please Connect your Wallet before accessing the loan interface", type: "error"});
            return;
        }

        if (loading) return ;

        const { hide: hidePending } = addToast({
            body: "Fetching User Loans",
            type: "loading",
            hideAfter: 0,
        });


        if (error)
        {
            hidePending();
            addToast({body: "There was a problem loading the user loans", type: "success"});
        }

        console.log(data)

        let i;
        // get all loan ids open
        let temp_json = {}
        let temp_array = []

        for (i=0; i < data['loans'].length;i++) {

            console.log(data['loans'][i].borrower)
            console.log(account)

            if (data['loans'][i].borrower.toLowerCase() === account.toLowerCase() && data['loans'][i].loanOpen === true) {

                const coll_contract = isAddress(data['loans'][i].collateralAddress) && !!COLL_ABI && !!library ? new Contract(data['loans'][i].collateralAddress, COLL_ABI, library) : undefined;

                let cratio = BigNumber.from("0")

                try {
                    cratio = await coll_contract.getLoanCollateralRatio(account, data['loans'][i].loanID)
                } catch {}

                temp_json = {}
                temp_json.Name = data['loans'][i].symbol
                temp_json.CRatio = format_friendly(cratio.mul(100), 4) + "%"
                temp_json.loanAmount = formatEther(data['loans'][i].loanAmount)
                temp_json.Address = data['loans'][i].collateralAddress
                temp_json.LoanID = data['loans'][i].loanID
                temp_array.push(temp_json)
            }
        }

        if (temp_array.length === 0) {
            temp_array.push(
                {
                    name: "" ,
                    symbol: "No open loans found",
                    amount_borrowed: "",
                    collateral_ratio: "",
                }
            )
        }

        setLoanTableData(temp_array)

        hidePending();
        addToast({body: "Loading successful", type: "success"});
    }

    // effect hook for updating data
    useEffect(() => {

        if (account)
        {
            getUserLoans();
        }

    }, [account, loading]);

  return (
    <div className="loan">
      <div className="loan-table" style={{ width: "100%" }}>
        <Table
          data={LoanTableData}
          heading={"Open Loans"}
          onClick={onRowClick}
          icon={OpenLoanIco}
          colors={{ amount_borrowed: "#0499A5" }}
        />
      </div>
      <Modal
        style={{ width: "54%", top: "20%", left: "30%" }}
        toggleShow={() => {
          setModal(!modal);
        }}
        show={modal}
      >
        <LoanModal setModal={setModal} clickedRow={clickedRow} />
      </Modal>
    </div>
  );
};
export default Loan;
