import React, { Component, useEffect, useState } from "react";
import { StakeData } from "../../data";
import "./stake.scss";
import DropdownIco from "../../assets/others/down_button.svg";
import CurrencyIco from "../../assets/others/LogoCircle@2x.png";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import {InfuraProvider, JsonRpcProvider} from "@ethersproject/providers";
import {useWeb3React} from "@web3-react/core";
import ERC20 from "../../constants/abi/erc20.json";
import POOL_ABI from "../../constants/abi/poollp.json";
import useErc20Contract from "../../hooks/useErc20Contract";
import {Contract} from "@ethersproject/contracts";
import {isAddress} from "@ethersproject/address";
import {formatEther} from "@ethersproject/units";

const StakeBlock = ({ data }) => {
  let history = useHistory();
    const {account, library} = useWeb3React();
    const erc20 = useErc20Contract(data.tokenAddress);
    const library_infura = new JsonRpcProvider("https://polygon-mainnet.infura.io/v3/fd93f678cd3b43dbbe5e8816e390fd99")
    const contract = isAddress(data.poolAddress) && !!POOL_ABI && !!library_infura ? new Contract(data.poolAddress, POOL_ABI, library) : undefined;

    //set constants for later displaying
    const [first_loop, set_first_loop] = useState(0);
    const [poolapy, setpoolapy] = useState(0)
    const [unistaked, setunistaked] = useState(0)
    const [rewardleft, setrewardleft] = useState(0)

    // effect hook for updating data
    useEffect(() => {

        // update the ui elements
        async function updateUIStates() {

            {
                    const totalstake = await contract.totalSupply()
                    setunistaked(formatEther(totalstake))

                    if (data.heading !== "Conjure (CNJ)")
                    {

                    const timefinish = await contract.periodFinish()
                    const acttime = Math.floor(new Date().getTime() / 1000);

                    let setime = 0
                    if (timefinish.toNumber() - acttime > 0)
                    {
                        setime = timefinish.toNumber() - acttime
                    }

                    let unix_timestamp = setime
                    // Create a new JavaScript Date object based on the timestamp
                    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                    var date = new Date(unix_timestamp * 1000);
                    // Hours part from the timestamp
                    var hours = date.getHours();
                    // Minutes part from the timestamp
                    var minutes = "0" + date.getMinutes();
                    // Seconds part from the timestamp
                    var seconds = "0" + date.getSeconds();

                    const alch_contract = isAddress(data.rewardsAddress) && !!ERC20 && !!library ? new Contract(data.rewardsAddress, ERC20, library) : undefined;
                    const rewardsleft = await alch_contract.balanceOf(data.poolAddress)
                    setrewardleft(formatEther(rewardsleft))

                    }

            }
        }

        // fix for updating after wallet login
        if (account && first_loop == 0)
        {
            set_first_loop(1);
            updateUIStates();
        }

        // schedule every 15 sec refresh
        const timer = setInterval(() => {
            if (account)
            {
                updateUIStates()
            }

        }, 10000);

        // clearing interval
        return () => clearInterval(timer);
    }, [ account, erc20, contract] );

  const goToDetails = ({ id, status }) => {
    history.push(`/main/stake/details/${id}/${status}`);
  };

  console.log(data)

  return (
    <div className="stake-block">
      <div className="header do-between">
        <div style={{ width: "12%" }}>
          <img src={data.icon || CurrencyIco} />
        </div>
        <span
          style={{
            width: "63%",
            color: "#CACBCD",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {data.heading}
        </span>
        <span className="status" style={{ width: "20%" }}>
          {data.status}
        </span>
      </div>
      <div
        className="details"
        style={{ width: "65%", marginLeft: "10%", marginRight: "25%" }}
      >
        <div style={{ minHeight: "30px" }}>
          {data.status == "stake" && (
            <div>
              <div className="do-between" style={{ flexWrap: "wrap" }}>
                <span>Staked : {unistaked} </span>
              </div>
            </div>
          )}
        </div>

          {data.heading === "Conjure (CNJ)" ?
              <div>
                  <div> Earn CNJ Rewards for every mint in the protocol</div>
              </div>
          :
              <div>
                  <div> Rate&ensp; :&ensp; {data.rate}</div>
                  <div> Rewards Left&ensp; :&ensp; {rewardleft}</div>
              </div>
          }


        <div></div>
      </div>
      <div style={{ margin: "20px", marginLeft: "10%" }}>
        <div
          className="connect-button"
          onClick={() => goToDetails({ id: data.id, status: data.status })}
        >
          {data.status == "finished" ? "view" : "stake"}
        </div>
      </div>
    </div>
  );
};
const Stake = () => {
  const [stakeData, setStakeData] = useState(StakeData);

  useEffect(() => {
    //call api for stakeData details
  });

  const connectWallet = () => {};

  return (
    <div className="stake">
      {stakeData.map((i, k) => {
        return <StakeBlock data={i} />;
      })}
    </div>
  );
};
export default Stake;
