import React, {Component, useEffect, useState} from "react";
import {ManageTableData, AssetDetails} from "../../data";
import Table from "../subcomponents/table";
import "./manage.scss";
import {useLocation, useHistory, Redirect} from "react-router-dom";
import backIco from "../../assets/others/BackButton.svg";
import RecentIco from "../../assets/others/RecentEventIcon.svg";
import {DateTime} from "luxon";
import {isAddress} from "@ethersproject/address";
import {Contract} from "@ethersproject/contracts";
import {addToast, PendingTx, SuccessfulTx, PendingIPFS, SuccessfulIPFS} from "../../hooks/useToast";
import getReceipt from "../../lib/getReceipt";
import {BigNumber} from '@ethersproject/bignumber';
import {formatEther, parseEther, formatUnits} from "@ethersproject/units";
import {
    CONJURE_FACTORY_ADDRESS,
    CHAINLINK_OPTIONS,
    ETHUSD_CHAINLINK_ADDRESS,
} from "../../constants";
import {useWeb3React} from "@web3-react/core";
import ABI from "../../constants/abi/Conjure.json";
import COLLATERAL_ABI from "../../constants/abi/EtherCollateral.json";
import Select, {components} from 'react-select'
import {InfuraProvider, JsonRpcProvider} from "@ethersproject/providers";
import {defaultAbiCoder, Interface} from "@ethersproject/abi";

const encoder = defaultAbiCoder

const SectionBlock = ({
                          type,
                          data,
                          onCancel,
                          onChange,
                          index,
                          collectFees,
                          account,
                          owner,
                          avail,
                          feeds,
                          assettype
                      }) => {

    console.log(assettype)

    function getOracleType(oratype) {
        if (oratype === 0) {
            return "Chainlink";
        }
        if (oratype === 1) {
            return "Uniswap";
        }

        return "Custom";
    }

    return (
        <div className="section-block">
            <div className="heading">Asset consists of</div>

            {feeds && feeds.map((prop, i) => (
                <>
                    <Input
                        className={"item item2"}
                        value={"Type: " + getOracleType(prop.oracleType.toNumber())}
                        placeholder="Type"
                    />

                    <Input
                        className={"item item2"}
                        value={"Address: " + prop.oracleaddress}
                        placeholder="Address"
                    />

                    {assettype && assettype.toNumber() === 0 ?
                        ""
                        :
                        <Input
                            className={"item item2"}
                            value={"Weight: " + prop.weight + "%"}
                            placeholder="Weight"
                        />
                    }

                    <div style={{width: "100%", height: "20px"}}></div>
                </>
            ))}
            {account && account === owner ?
                <>
                    <div style={{width: "100%", height: "50px"}}></div>

                    <div className={"item item2"}>
                        <span>Balance: </span>
                        <span>{avail} MATIC</span>
                    </div>
                    <div className="connect-button" onClick={collectFees}>collect fee</div>
                </>
                :
                ""
            }

        </div>

    );
};

const Button = ({name, onClick}) => {
    return <span className="small-button">{name}</span>;
};


const ManageDetails = ({
                           type,
                           data,
                           assetData,
                           inputs,
                           setInputs,
                           setAssetData,
                           updatePrice,
                           setOwner,
                           collectFees,
                           changeOwner,
                           newowner,
                           account,
                           manageTableData,
                           openLoan
                       }) => {
    console.log(type);
    return (
        <div>
            <div className="manage-details">
                <div className="main-heading">Details for Asset {assetData.name} ({assetData.symbol})</div>
                <div style={{width: "90%"}}>
                    <div className="section">
                        <div>
                            <div className="section-data">
                                <div>
                                    <span>Your Asset balance</span>
                                    <span>{assetData.userbalance} {assetData.symbol}</span>
                                </div>
                                <div>
                                    <span>Latest Price</span>
                                    <span>${assetData.price}</span>
                                </div>
                                <div>
                                    <span>Last Price Update</span>
                                    <span>{assetData.recordedOn}</span>
                                </div>
                                <div>
                                    <span>Assets in circulation</span>
                                    <span>{assetData.assetsInCirculation}</span>
                                </div>
                                <div>
                                    <span>Price at Creation</span>
                                    <span>${assetData.priceAtCreation}</span>
                                </div>
                                <div>
                                    <span>Inverse Asset</span>
                                    {assetData.inverse === true ?
                                        <span>True</span>
                                        :
                                        <span>False</span>
                                    }

                                </div>
                            </div>
                            {account && assetData.owner === account ?
                                <div className="bottom-bar do-center">
                                    <Input
                                        className={"item item2"}
                                        onChange={(e) =>
                                            changeOwner(e.target.value)
                                        }
                                        value={newowner}
                                        placeholder="Change Owner to"
                                    />
                                    <div className="connect-button inside-button" onClick={setOwner}>
                                        Change Owner
                                    </div>
                                </div>
                                :
                                ""
                            }

                        </div>
                        <div>
                            <SectionBlock data={inputs} onChange={setInputs} type={type} collectFees={collectFees}
                                          account={account} owner={assetData.owner} avail={assetData.assetBalance}
                                          feeds={assetData.pricefeeds} assettype={assetData.assettype}/>
                        </div>
                    </div>
                </div>
                {account ?
                    <div className="connect-button big-button" onClick={updatePrice}>Update Price</div>
                    :
                    ""
                }
                <div className="connect-button big-button" onClick={openLoan}>Open Loan</div>

            </div>

        </div>
    );
};

const Input = ({onChange, placeholder, value, className}) => {
    return (
        <div className={className}>
            <input onChange={onChange} placeholder={placeholder} value={value}/>
        </div>
    );
};

const Manage = ({match}) => {
    let history = useHistory();

    const [manageInput, setManageInput] = useState();
    // const [id, setId] = useState(match);
    // const [detailsOpened, setDetailsOpened] = useState(false);
    const [assetData, setAssetData] = useState(AssetDetails);
    const [manageTableData, setManageTableData] = useState(ManageTableData);
    const [type, setType] = useState("table");
    const [newowner, setnewowner] = useState("");

    const [first_router, set_first_router] = useState(0);
    const [first_autocheck, set_first_autocheck] = useState(0);
    const [check_query, set_check_query] = useState(0);
    const [recent_events, set_recent_events] = useState([{Event: "Events are loading"}]);

    const location = useLocation();
    const library_infura = new JsonRpcProvider("https://polygon-mainnet.infura.io/v3/fd93f678cd3b43dbbe5e8816e390fd99")
    const {account, library} = useWeb3React();

    console.log('in')

    const [inputs, setInputs] = useState({
        address: "",
        weight: "",
        address2: "",
        weight2: "",
        available: "",
        changeOwner: "",
    });

    useEffect(() => {
    });

    const updatePrice = async () => {
        const conjure_contract = isAddress(manageInput) && !!ABI && !!library ? new Contract(manageInput, ABI, library.getSigner(account)) : undefined;
        try {
            const {hash} = await conjure_contract.updatePrice();
            await getReceipt(hash, library);
            await getDetails();
        } catch (e) {
            addToast({body: e.message, type: "error"});
        }
    }

    async function changeOwner() {
        const conjure_contract = isAddress(manageInput) && !!ABI && !!library ? new Contract(manageInput, ABI, library.getSigner(account)) : undefined;

        try {
            const {hash} = await conjure_contract.changeOwner(newowner);
            await getReceipt(hash, library);
            setnewowner("");
            await getDetails();
        } catch (e) {
            addToast({body: e.message, type: "error"});
        }
    }

    function openLoan() {
        console.log('in')
        history.push({pathname: "/main/home/" + manageInput});
    }

    async function collectFees() {
        const conjure_contract = isAddress(manageInput) && !!ABI && !!library ? new Contract(manageInput, ABI, library.getSigner(account)) : undefined;

        try {
            const {hash} = await conjure_contract.collectFees();
            await getReceipt(hash, library);
            await getDetails();
        } catch (e) {
            addToast({body: e.message, type: "error"});
        }
    }

    async function getConjureEvents(manageInput) {
        let callingaddress = manageInput;
        const conjure_contract = isAddress(callingaddress) && !!ABI && !!library_infura ? new Contract(callingaddress, ABI, library_infura) : undefined;

        const collateral_address = await conjure_contract._collateralContract();

        const collateral_contract = isAddress(collateral_address) && !!COLLATERAL_ABI && !!library_infura ? new Contract(collateral_address, COLLATERAL_ABI, library_infura) : undefined;


        let block = await library_infura.getBlockNumber()
        console.log(block)

        // get all the open loan events
        let filter_open = collateral_contract.filters.LoanCreated();
        let filter_close = collateral_contract.filters.LoanClosed();

        let past_events_loan_open = []
        let past_events_loan_close = []

        let i;
        for (i=14518760; i < block; i = i + 999999) {
            let past_events_temp = await library_infura.getLogs({
                fromBlock: i,
                toBlock: i+ 1000000,
                address: collateral_contract.address,
                topics: filter_open['topics']
            });

            past_events_loan_open = past_events_loan_open.concat(past_events_temp)
            console.log(i)
        }

        for (i=14518760; i < block; i = i + 999999) {
            let past_events_temp = await library_infura.getLogs({
                fromBlock: i,
                toBlock: i+ 1000000,
                address: collateral_contract.address,
                topics: filter_close['topics']
            });

            past_events_loan_close = past_events_loan_close.concat(past_events_temp)
            console.log(i)
        }



        const eventParser = new Interface(COLLATERAL_ABI)

        let contract_array = [];

        past_events_loan_open?.map(event => {
            const eventParsed = eventParser.parseLog(event).args
            contract_array.push({
                name: "Mint",
                blockNumber: event.blockNumber,
                address: eventParsed.account,
                amount: eventParsed.amount.toString(),
                loanID: eventParsed.loanID
            })
        })

        past_events_loan_close?.map(event => {
            const eventParsed = eventParser.parseLog(event).args

            contract_array.push({
                name: "Burn",
                blockNumber: event.blockNumber,
                address: eventParsed.account,
                amount: 0,
                loanID: eventParsed.loanID
            })
        })

        console.log(contract_array)

        // get all loan ids open
        let temp_conj_info = [];

        let k;
        for (k = 0; k < contract_array.length; k++) {
            const blockData = await library_infura.getBlock(contract_array[k].blockNumber)

            let enddate = DateTime.fromSeconds(blockData.timestamp)

            if (contract_array[k].name == "Burn") {
                const loan = await collateral_contract.getLoan(contract_array[k].address, contract_array[k].loanID);
                console.log(loan)
                const temp_amount = loan.loanAmount;

                contract_array[k].amount = temp_amount.toString();
            }

            temp_conj_info.push({
                name: contract_array[k].name,
                address: contract_array[k].address,
                amount: contract_array[k].amount,
                createtime: enddate.toLocaleString(DateTime.DATETIME_SHORT),
                inner_date: enddate
            });
        }

        temp_conj_info.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.inner_date) - new Date(a.inner_date);
        });

        let final_array = [];
        let l;
        for (l = 0; l < temp_conj_info.length; l++) {
            final_array.push({
                name: temp_conj_info[l].name,
                address: temp_conj_info[l].address,
                amount: formatEther(temp_conj_info[l].amount),
                createtime: temp_conj_info[l].createtime
            });
        }

        if (final_array.length === 0) {
            final_array.push({Events: "No recent events found"});
        }

        set_recent_events(final_array);
    }


    const getDetails = async () => {
        //call api for assetData

        console.log(manageInput)
        const conjure_contract = isAddress(manageInput) && !!ABI && !!library_infura ? new Contract(manageInput, ABI, library_infura) : undefined;

        const {hide: hidePending} = addToast({
            body: "Checking Conjure Details",
            type: "loading",
            hideAfter: 0,
        });

        try {
            const owner = await conjure_contract._owner();
            console.log(owner)

            const totalsupply = await conjure_contract.totalSupply();
            const symbol = await conjure_contract.symbol();
            const decimals = await conjure_contract.decimals();
            const name = await conjure_contract.name();
            const assettype = await conjure_contract._assetType();
            const lastprice = await conjure_contract.getLatestPrice();
            const lastpricetime = await conjure_contract.getLatestPriceTime();
            const deploymentprice = await conjure_contract._deploymentPrice();
            const inverser = await conjure_contract._inverse();
            const balance = await library_infura.getBalance(conjure_contract.address);
            let userbalance = "0";
            const oraclenumber = await conjure_contract._numoracles();

            let ora_array = [];

            let i;
            for (i = 0; i < oraclenumber.toNumber(); i++) {
                let temp_prop = await conjure_contract._oracleData(i);
                ora_array.push(temp_prop);
            }

            let enddate = DateTime.fromSeconds(lastpricetime.toNumber());
            let formatenddate = enddate.toLocaleString(DateTime.DATETIME_SHORT);

            if (account) {
                userbalance = await conjure_contract.balanceOf(account);
            }

            setAssetData(
                {
                    assetBalance: formatEther(balance),
                    name: name,
                    symbol: symbol,
                    price: formatEther(lastprice),
                    assetsInCirculation: formatEther(totalsupply),
                    priceAtCreation: formatEther(deploymentprice),
                    recordedOn: formatenddate,
                    owner: owner,
                    userbalance: formatEther(userbalance),
                    pricefeeds: ora_array,
                    assettype: assettype,
                    inverse: inverser
                }
            )


            if (account === owner) {
                setType("owner");
            } else {
                setType("user");
            }

            //await getConjureEvents(manageInput);

            history.push(`/main/manage/${manageInput}`);

            hidePending();
            addToast({body: "Loading successful", type: "success"});

        } catch (e) {
            console.log(e)
            history.push(`/main/manage/table`);

            hidePending();
            addToast({body: "The address provided does not resolve to a valid Conjure address", type: "error"});
        }


    };

    const detailsClear = () => {
        history.push("/main/manage/table");

        // setDetailsOpened(false);
        // setType("user");
    };

    // effect hook for updating data
    useEffect(() => {

        if (first_router === 0) {
            set_first_router(1);
            {
                const splitter = location.pathname.split("/")[3];
                console.log(splitter);

                try {
                    let my_address = splitter;
                    console.log(my_address);

                    if (my_address !== undefined && my_address !== 'table') {
                        setManageInput(my_address);
                        set_check_query(1);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, []);

    // effect hook for updating data
    useEffect(() => {

        // update the ui elements
        async function updateUIStates() {
            console.log('updating')
            await getDetails();
            console.log('updating finished')
        }

        // schedule
        const timer = setInterval(() => {
            if (first_autocheck === 0 && manageInput !== '' && check_query === 1) {
                console.log('first auto check')
                console.log(first_autocheck)
                set_first_autocheck(1);
                updateUIStates();
            }
        }, 300);

        // clearing interval
        return () => clearInterval(timer);
    }, [manageInput, first_autocheck, check_query]);

    useEffect(() => {
        setType(location.pathname.split("/")[3]);
        console.log(type, location);
        // getDetails();
    }, [location]);

    return (
        <div className="manage">
            {type != "table" && (
                <span
                    onClick={() => detailsClear()}
                    style={{textAlign: "left", margin: "10px 0", cursor: "pointer"}}
                >
          <img src={backIco}/>
        </span>
            )}
            {type != "asset" ? (
                <div className="details-section do-center">
                    <div>manage your conjure address</div>
                    <div className="item">
                        <input
                            placeholder="Enter Conjure Address or ENS Name"
                            onChange={(e) => setManageInput(e.target.value)}
                            value={manageInput}
                        />
                    </div>
                    <div className="connect-button" onClick={getDetails}>
                        get details
                    </div>
                </div>
            ) : null}
            {type != "table" ? (
                <ManageDetails
                    assetData={assetData}
                    setAssetData={setAssetData}
                    type={type}
                    inputs={inputs}
                    setInputs={setInputs}
                    updatePrice={updatePrice}
                    setOwner={changeOwner}
                    collectFees={collectFees}
                    changeOwner={setnewowner}
                    newowner={newowner}
                    account={account}
                    manageTableData={recent_events}
                    openLoan={openLoan}
                />
            ) : (
                ""
            )}
        </div>
    );
};
export default Manage;
